// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

// jQuery
import JQuery from 'jquery';
window.$ = window.JQuery = JQuery;

// Bootstrap
import "bootstrap"
$(document).on('turbolinks:load', function() {
  $('[data-bs-toggle="tooltip"]').tooltip()
  $('[data-bs-toggle="popover"]').popover()

  $('.toast').toast({
      autohide: true,
      animation: true,
      delay: 5000
  })
  $('#toast').toast('show')
});

// select2
import 'select2'

const images = require.context('../images/', true)

Rails.start()
Turbolinks.start()
ActiveStorage.start()

// scss
import '../stylesheets/application';
